import React from "react"
import { Link } from "gatsby"
import { Div, Text, Row, Col, Container, Anchor, Icon } from "atomize"

const footerLinks = {
  docs: [
    {
      name: "Installation",
      link: "/docs/react/setup",
    },
    {
      name: "Theme Setup",
      link: "/docs/react/theme",
    },
    {
      name: "Grid",
      link: "/docs/react/grid",
    },
    {
      name: "Atoms",
      link: "/docs/react/atoms",
    },
    {
      name: "Molecules",
      link: "/docs/react/molecules",
    },
    {
      name: "Functions",
      link: "/docs/react/functions",
    },
  ],
  atomize: [
    {
      name: "Features",
      link: "#features",
    },
    {
      name: "Design",
      anchor: true,
      link: "http://www.atomizedesign.com/",
    },
    {
      name: "Development",
      link: "/docs/react/intro",
    },
    // {
    //   name: "Github",
    //   anchor: true,
    //   link: "https://github.com/Proksh/atomize",
    // },
  ],
  resources: [
    {
      name: "Sketch File",
      anchor: true,
      link: "https://gumroad.com/l/atomize",
    },
    {
      name: "Github",
      anchor: true,
      link: "https://github.com/Proksh/atomize",
    },
  ],
  about: [
    // {
    //   name: "Introduction",
    //   link: "/docs/react/intro",
    // },
    {
      name: "Showcase",
      link: "/docs/react/showcase",
    },
    {
      name: "Contribute",
      link: "/docs/react/contributing",
    },
  ],
  extras: [
    {
      name: "Blog",
      anchor: true,
      link:
        "https://hashnode.com/post/getting-started-with-react-atomize-and-gatsby-cjyfgtj0m001obzs19cobmogi",
    },
    // {
    //   name: "Product Hunt",
    //   anchor: true,
    //   link: "/",
    // },
    {
      name: "Need Help?",
      anchor: true,
      link: "mailto:proksh@atomizedesign.com",
    },
    {
      name: "Give Feedback",
      anchor: true,
      link: "mailto:proksh@atomizedesign.com",
    },
  ],
}

// const mediaLinks = [
//   {
//     icon: "Github",
//     link: "https://github.com/proksh",
//   },
//   {
//     icon: "Behance",
//     link: "https://www.behance.net/prokshh90d1",
//   },
//   {
//     icon: "Dribbble",
//     link: "https://dribbble.com/proksh",
//   },
//   {
//     icon: "Twitter",
//     link: "https://twitter.com/proksh_luthra",
//   },
//   {
//     icon: "Linkedin",
//     link: "https://www.linkedin.com/in/prokshluthra/",
//   },
// ]

const serviceLinks = [
  {
    title: "Aviso de privacidad",
    link: "https://filehire.com/privacy",
  },
  {
    title: "Terminos y condiciones",
    link: "https://filehire.com/service",
  }
]

const Footer = () => {
  return (
    <Div
      tag="footer"
      p={{ t: { xs: "12rem", md: "8rem" }, b: { xs: "4rem", md: "5rem" } }}
      pos="relative"
    >
      <Container>
    {/*    <Div m={{ b: "8rem" }} d={{ xs: "none", md: "block" }}>
          <Row>
            {Object.keys(footerLinks).map((key, index) => {
              return (
                <Col
                  size={{ xs: 6, md: 2 }}
                  offset={{ xs: 0, md: index === 0 && 1 }}
                >
                  <Div
                    p={{ l: { md: "2.5rem" }, b: { xs: "1.5rem", md: "0" } }}
                    textColor="medium"
                  >
                    <Text
                      m={{ b: "1rem" }}
                      textColor="black"
                      textTransform="capitalize"
                      textWeight="500"
                    >
                      {key}
                    </Text>
                    {footerLinks[key].map((link, i) => {
                      if (link.anchor) {
                        return (
                          <Anchor
                            m={{ b: "0.5rem" }}
                            textColor="medium"
                            hoverTextColor="info800"
                            d="block"
                            href={link.link}
                            target="_blank"
                            textWeight="400"
                          >
                            {link.name}
                          </Anchor>
                        )
                      } else {
                        return (
                          <Link to={link.link}>
                            <Text
                              m={{ b: "0.5rem" }}
                              textColor="medium"
                              hoverTextColor="info800"
                            >
                              {link.name}
                            </Text>
                          </Link>
                        )
                      }
                    })}
                  </Div>
                </Col>
              )
            })}
          </Row>
        </Div>*/}

        <Div d="flex" align="center" justify="center" flexDir="column">
          <Text
            tag="h2"
            textWeight="400"
            textSize="body"
            textAlign="center"
            m={{ b: "1rem" }}
          >
            2023 © FileHire
          </Text>
          <Div d="flex" align="center" justify="center">
            {serviceLinks.map((link, index) => (
              <Anchor href={link.link}
                  m={{ r: "2rem", b: { xs: "1rem", md: "0" } }}
                  textWeight="500"
                  textColor="medium"
                  hoverTextColor="black"
                  transition
                >
                  {link.title}
              </Anchor>
            ))}
          </Div>
        </Div>
      </Container>
    </Div>
  )
}

export default Footer
