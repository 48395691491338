import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/common/header";
import { Text, Div, Anchor } from "atomize"
// import GetStartedBanner from "../components/homepage/GetStartedBanner"
import Footer from "../components/homepage/Footer";
import DocsWrapper from "../components/common/DocsWrapper"

const PrivacyPage = () => (
  <Layout>
      <div>
        <SEO
          title="Aviso de Privacidad"
          description="Mass Job application. Aplica de forma instantanea a cientos de vacantes disponibles para ti. Agrega tus datos, aplica y mira como te llegan ofertas al instante."
        />
        <Header />
          <Div p={{ x: { xs: "1.5rem", md: "8rem" }, t: "10rem", b: "10rem" }}>
            <Text tag="h2" textWeight="400" textSize="display2" m={{ b: "4rem" }}>
              Quopard LATAM S.A de C.V Política de Privacidad
            </Text>
            <Text m={{ b: "3rem" }} textColor="medium">
              Esta Política de Privacidad describe cómo se recopila, utiliza y comparte su información personal cuando visita o realiza una compra en https://filehire.com/ (el "Sitio").
            </Text>
            <Text textSize="heading" textWeight="500" m={{ b: "0.5rem" }}>
              INFORMACIÓN PERSONAL QUE RECOPILAMOS
            </Text>
            <Text m={{ b: "1.5rem" }} textColor="medium">
            Cuando usted visita el Sitio, automáticamente recopilamos cierta información sobre su dispositivo, incluyendo información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que están instaladas en su dispositivo. Además, a medida que navega por el sitio, recopilamos información sobre las páginas web o productos que visita, qué sitios web o términos de búsqueda le han remitido al sitio e información sobre cómo interactúa con el sitio. Nos referimos a esta información recopilada automáticamente como "Información del dispositivo".

            Recopilamos la Información del Dispositivo utilizando las siguientes tecnologías:

              - Las "cookies" son archivos de datos que se colocan en su dispositivo u ordenador y que a menudo incluyen un identificador único anónimo. Para obtener más información sobre las cookies y cómo desactivarlas, visite http://www.allaboutcookies.org.
              - Los "archivos de registro" rastrean las acciones que se producen en el Sitio y recopilan datos como su dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia/salida y sellos de fecha/hora.
              - Las "balizas web", "etiquetas" y "píxeles" son archivos electrónicos que se utilizan para registrar información sobre su navegación por el Sitio.
              - El fingerprinting (huella digital) es una técnica que se utiliza para recopilar información sobre un usuario basada en las características únicas de su dispositivo, como su dirección IP, sistema operativo, navegador y otras características. Esta información se utiliza para crear una huella digital única para el usuario, que se puede utilizar para realizar un seguimiento de su comportamiento en línea a lo largo del tiempo.
            Además, cuando realiza una compra o intenta realizar una compra a través del Sitio, recopilamos cierta información sobre usted, como su nombre, dirección de facturación, dirección de envío, información de pago (incluidos los números de tarjeta de crédito [[INSERTAR OTROS TIPOS DE PAGO ACEPTADOS]]), dirección de correo electrónico y número de teléfono.  Nos referimos a esta información como "Información del Pedido".

            Cuando hablamos de "Información Personal" en esta Política de Privacidad, nos referimos tanto a la Información del Dispositivo como a la Información del Pedido.

            </Text>
            <Text textSize="heading" textWeight="500" m={{ b: "0.5rem" }}>
            ¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?
            </Text>
            <Text textSize="subheader" textWeight="500" m={{ b: "0.5rem" }}>
              Sending a pull request
            </Text>
            <Text m={{ b: "1.5rem" }} textColor="medium">
              Utilizamos la Información del Pedido que recopilamos generalmente para cumplir con cualquier pedido realizado a través del Sitio (incluido el procesamiento de su información de pago, la organización del envío y el envío de facturas y/o confirmaciones de pedidos).  Además, utilizamos esta Información de Pedido para:
              Comunicarnos con usted;
              Analizar nuestros pedidos para detectar posibles riesgos o fraudes; y
              Cuando esté en línea con las preferencias que ha compartido con nosotros, proporcionarle información o publicidad relacionada con nuestros productos o servicios.
              Análisis de ventas: La información del pedido de un usuario se puede utilizar para realizar un análisis detallado de las ventas de una empresa, incluyendo el seguimiento de la cantidad de pedidos, los productos vendidos, el valor promedio de los pedidos y otras métricas relevantes.
              Gestión de inventario: La información del pedido también se puede utilizar para realizar un seguimiento del inventario de una empresa y gestionar la cadena de suministro. Por ejemplo, un sistema de gestión de inventario podría utilizar la información del pedido para actualizar el inventario disponible y garantizar que se reabastezcan los productos agotados.
              Marketing personalizado: La información del pedido se puede utilizar para personalizar la experiencia del usuario y ofrecer recomendaciones de productos personalizadas. Por ejemplo, una empresa de comercio electrónico podría utilizar la información del pedido para recomendar productos similares o complementarios a los que un usuario ya ha comprado.
              Atención al cliente: La información del pedido también se puede utilizar para proporcionar un mejor servicio de atención al cliente, incluyendo la gestión de devoluciones y cambios, el seguimiento de los problemas de entrega y la solución de problemas técnicos.
              Utilizamos la Información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su dirección IP) y, de forma más general, para mejorar y optimizar nuestro Sitio (por ejemplo, generando análisis sobre cómo nuestros clientes navegan e interactúan con el Sitio, y para evaluar el éxito de nuestras campañas de marketing y publicidad).
              Publicidad personalizada: La información del dispositivo, como el tipo de dispositivo, la ubicación y la actividad en línea, se puede utilizar para ofrecer publicidad personalizada a los usuarios. Por ejemplo, si un usuario ha estado buscando zapatillas deportivas en su teléfono móvil, es posible que vea anuncios de zapatillas deportivas en su feed de redes sociales o en los sitios web que visita.
              Retargeting: La información del dispositivo se puede utilizar para realizar campañas de retargeting. Por ejemplo, si un usuario visita un sitio web de comercio electrónico y añade un producto a su carrito de compras, pero no completa la transacción, se puede utilizar la información del dispositivo para mostrar anuncios relacionados con ese producto en otros sitios web que el usuario visite posteriormente.
              Optimización del sitio web: La información del dispositivo se puede utilizar para optimizar la experiencia del usuario en un sitio web. Por ejemplo, si se sabe que la mayoría de los usuarios acceden al sitio web desde un teléfono móvil, se puede optimizar el diseño del sitio para que se vea y funcione bien en pantallas más pequeñas.
              Análisis del comportamiento del usuario: La información del dispositivo se puede utilizar para realizar un análisis detallado del comportamiento del usuario en línea, incluyendo la duración de la sesión, las páginas visitadas y el tiempo de carga. Estos datos pueden ayudar a las empresas a mejorar la experiencia del usuario y a identificar posibles problemas con el sitio web o la aplicación.
              INTERCAMBIO DE SUS DATOS PERSONALES

              Compartimos su Información Personal con terceros para que nos ayuden a utilizar su Información Personal, tal y como se ha descrito anteriormente.  Por ejemplo, utilizamos Shopify para impulsar nuestra tienda en línea; puede obtener más información sobre cómo Shopify utiliza su Información Personal aquí: https://www.shopify.com/legal/privacy.  También utilizamos Google Analytics para ayudarnos a comprender cómo utilizan nuestros clientes el Sitio; puede obtener más información sobre cómo utiliza Google su Información Personal aquí: https://www.google.com/intl/en/policies/privacy/.  También puede darse de baja de Google Analytics aquí: https://tools.google.com/dlpage/gaoptout.

              Por último, también podremos compartir sus Datos Personales para cumplir las leyes y normativas aplicables, para responder a una citación, orden de registro u otra solicitud legal de información que recibamos, o para proteger de otro modo nuestros derechos.
              [[INCLUIR SI SE UTILIZA REMARKETING O PUBLICIDAD DIRIGIDA]]
              PUBLICIDAD BASADA EN EL COMPORTAMIENTO
              Como se ha descrito anteriormente, utilizamos sus Datos Personales para ofrecerle publicidad dirigida o comunicaciones de marketing que creemos que pueden ser de su interés.  Para obtener más información sobre el funcionamiento de la publicidad dirigida, puede visitar la página educativa de la Network Advertising Initiative ("NAI") en http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.

              Puede darse de baja de la publicidad dirigida:
              [[
                INCLUIR ENLACES DE EXCLUSIÓN EN LOS SERVICIOS QUE SE UTILICEN.
                LOS ENLACES MÁS COMUNES SON:
                  FACEBOOK - https://www.facebook.com/settings/?tab=ads
                  GOOGLE - https://www.google.com/settings/ads/anonymous
                  BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
              ]]
              Además, puede darse de baja de algunos de estos servicios visitando el portal de exclusión de la Digital Advertising Alliance en: http://optout.aboutads.info/.

              NO SEGUIR
              Tenga en cuenta que no alteramos las prácticas de recopilación y uso de datos de nuestro Sitio cuando vemos una señal Do Not Track de su navegador.
            </Text>

            <Text textSize="heading" textWeight="500" m={{ b: "0.5rem" }}>
              SUS DERECHOS
            </Text>
            <Text m={{ b: "3rem" }} textColor="medium">
            Si usted es residente europeo, tiene derecho a acceder a la información personal que tenemos sobre usted y a solicitar que su información personal sea corregida, actualizada o eliminada. Si desea ejercer este derecho, póngase en contacto con nosotros a través de la información de contacto que figura a continuación.
            Además, si usted es residente europeo, le informamos de que estamos procesando su información para cumplir los contratos que podamos tener con usted (por ejemplo, si realiza un pedido a través del Sitio), o para perseguir nuestros intereses comerciales legítimos enumerados anteriormente.  Además, tenga en cuenta que su información será transferida fuera de Europa, incluyendo Canadá y Estados Unidos.
            </Text>

            <Text textSize="heading" textWeight="500" m={{ b: "0.5rem" }}>
              CONSERVACIÓN DE DATOS
            </Text>
            <Text m={{ b: "3rem" }} textColor="medium">
            Cuando realice un pedido a través del Sitio, conservaremos la Información de su Pedido en nuestros registros, a menos y hasta que nos solicite que eliminemos esta información.

            MENORES
            El Sitio no está destinado a personas menores de 17 años de edad.

            CAMBIOS
            Podemos actualizar esta política de privacidad de vez en cuando para reflejar, por ejemplo, cambios en nuestras prácticas o por otras razones operativas, legales o reglamentarias.

            PÓNGASE EN CONTACTO CON NOSOTROS
            Si desea obtener más información sobre nuestras prácticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, póngase en contacto con nosotros por correo electrónico en la dirección alberto@quopard.com o por correo postal utilizando los datos que se facilitan a continuación:

            De Los Mayas 7 Sta. Cruz Acatlán, Naucalpan de Juárez, MEX, 53150, México
            </Text>

          </Div>


        <Footer />
      </div>
  </Layout>
);

export default PrivacyPage;
